import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import useWindowSize from '@propertypal/shared/src/hooks/useWindowSize';
import OpeningTimesIcon from '@propertypal/shared/src/resources/icons/new-homes/opening-times-icon-sm.svg';
import PPAwards from '@propertypal/shared/src/resources/logos/pp-awards-2023.png';
import PPAwardsWinners from '@propertypal/shared/src/resources/logos/pp-awards-winners.png';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import { HomepageTakeover } from '@propertypal/shared/src/types/homepageTakeover';
import ModalWrapper from '@propertypal/web-ui/src/modal/ModalWrapper';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { FunctionComponent, VideoHTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import {
  Container as InfoBar,
  Address,
  Content as InfoBarContent,
  CustomLink,
  CustomText,
  ImageWrapper,
  MobileText,
} from '../animation/ImageInfoBar.style';
import { Container, Content, ImageBox } from '../animation/ImageSlideshow.style';
import Chevron from '../icons/Chevron';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import PropertyPrice from '../property/PropertyPrice';
import { Text } from '../typography';
import {
  VideoBox,
  FullscreenContainer,
  FullscreenBtn,
  BackToHome,
  Title,
  IconWrapper,
  ShowHomeMob,
  MobileVideoBox,
  TakeoverLogo,
} from './Takeover.style';

interface Props {
  takeover: HomepageTakeover;
  children?: ReactNode;
}

const getAwardLogo = () => {
  const now = dayjs();
  const awardsDate = dayjs('2023-11-17T00:00:00+01:00');

  return now.isAfter(awardsDate) ? PPAwardsWinners.src : PPAwards.src;
};

const RenderVideo: FunctionComponent<{ takeover: HomepageTakeover; fullscreen: boolean }> = (props) => {
  const { takeover, fullscreen } = props;
  const window = useWindowSize();
  const videoProps: VideoHTMLAttributes<HTMLVideoElement> = {
    autoPlay: true,
    playsInline: true,
    controls: fullscreen,
    loop: true,
    poster:
      (takeover.video && takeover.video.coverImageUrl) ||
      (takeover.listing?.images && takeover.listing.images.length > 0 && takeover.listing.images[0].url) ||
      undefined,
  };

  if (takeover.video && window.width !== undefined) {
    if (window.width > 600) {
      return (
        <VideoBox {...videoProps} muted data-testid="takeoverVideo" fullscreen={fullscreen}>
          <source src={takeover.video.urls.desktop} type="video/mp4" />
        </VideoBox>
      );
    }

    if (window.width <= 600) {
      return (
        <MobileVideoBox {...videoProps} muted data-testid="takeoverVideo" fullscreen={fullscreen}>
          <source src={takeover.video.urls.mobile} type="video/mp4" />
        </MobileVideoBox>
      );
    }
  }

  return null;
};

const Takeover: FunctionComponent<Props> = (props) => {
  const router = useRouter();
  const fullscreen = router.query.fullscreen === 'true';
  const theme = useTheme();
  const property = props.takeover.listing;
  const price = property?.price;
  const hasShowHome = property && property.showHomeOpeningTime && !property.showHomeOpeningTime.byAppointment;
  const awardsLogo = useRef(getAwardLogo());

  const poster =
    props.takeover.altImage?.url ||
    (props.takeover.video && props.takeover.video.coverImageUrl) ||
    (property && property.images && property.images.length > 0 && property.images[0].url) ||
    undefined;

  const onClose = () => {
    // remove fullscreen query
    const { fullscreen: fllscrn, ...query } = router.query;
    router.push({ pathname: router.pathname, query }, undefined, {
      shallow: true,
    });
  };

  const gaPayload = {
    page: 'HP',
    location: 'Homepage Takeover',
    development_id: property?.path && property.path.split('/')[2].replace('d', ''),
  };

  const trackClick = () => {
    trackGaEvent('takeover_click', gaPayload);
  };

  useEffect(() => {
    trackGaEvent('takeover_impression', gaPayload);
  }, []);

  useEffect(() => {
    if (router.query.fullscreen === 'true') {
      trackGaEvent('takeover_fullscreen', gaPayload);
    }
  }, [router.query.fullscreen]);

  if (fullscreen && props.takeover.video) {
    return (
      <ModalWrapper scrollSelector="#fullscreen-vid" show={fullscreen} onClose={onClose}>
        <FullscreenContainer id="fullscreen-vid" data-testid="takeoverVideoFullscreen">
          <RenderVideo takeover={props.takeover} fullscreen />

          <BackToHome onClick={onClose}>
            <FontAwesomeIcon icon={faChevronLeft} color={theme.white} style={{ marginRight: '5px' }} /> Back to homepage
          </BackToHome>
        </FullscreenContainer>
      </ModalWrapper>
    );
  }

  return (
    <Container minHeight={props.takeover.title ? 480 : 360} data-testid="homepage-takeover">
      <Content>
        {props.takeover.video && (
          <>
            <RenderVideo takeover={props.takeover} fullscreen={false} />

            {!props.takeover.name.includes('propertypal-awards') && (
              <FullscreenBtn
                onClick={() => {
                  // update url so that when user hits browser back button it brings them back to homepage
                  router.push({ pathname: router.pathname, query: { ...router.query, fullscreen: true } }, undefined, {
                    shallow: true,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlay} color={theme.white} style={{ fontSize: 11, marginRight: '5px' }} /> Play
                video fullscreen
              </FullscreenBtn>
            )}
          </>
        )}

        {/* If only altImg is passed, will fallback to this if no video, or video errors */}
        {poster && (
          <ImageBox
            active
            data-testid="alt-image"
            src={poster}
            sizes="(max-width: 1200px) 1024px, 1600px"
            alt={property?.displayAddress || 'Superfeature Image'}
          />
        )}

        {props.takeover.title && <Title data-testid="takeover-title">{props.takeover.title}</Title>}

        {hasShowHome && (
          <ShowHomeMob data-testid="show-home-openings-mob">
            <Text color={theme.white} fontSize={14}>
              <Address>Show Home Opening:&nbsp;</Address>
              <Text as="span" color={theme.white} fontSize={14} fontWeight="bold">
                {property && property.showHomeOpeningTime && property.showHomeOpeningTime.defaultText}
              </Text>
            </Text>
          </ShowHomeMob>
        )}

        {property?.path && (
          <InfoBar active>
            <CustomLink href={property?.path} data-testid="info-bar" $hasShowHome={hasShowHome} onClick={trackClick}>
              <InfoBarContent>
                <ImageWrapper>
                  {property.listingLogos
                    ?.slice(0, 3)
                    .map((logo) => (
                      <img key={logo.standard} src={logo.standard} alt="Agent Logo" height={30} width="auto" />
                    ))}
                </ImageWrapper>

                <CustomText color={theme.white} fontSize={14}>
                  <Address>{property?.displayAddress} -&nbsp;</Address>
                  {price && <PropertyPrice as="span" price={price} fontSize={14} color={theme.white} />}
                </CustomText>

                <MobileText>
                  <Text color={theme.white} fontSize={14}>
                    {property?.displayAddressLine1}
                  </Text>
                  {price && <PropertyPrice as="span" price={price} fontSize={12} color={theme.white} />}
                </MobileText>
              </InfoBarContent>

              {hasShowHome && (
                <InfoBarContent $hasShowHome={hasShowHome} data-testid="show-home-openings">
                  <IconWrapper>
                    <OpeningTimesIcon />
                  </IconWrapper>
                  <CustomText color={theme.white} fontSize={14}>
                    <Address>Show Home Opening:&nbsp;</Address>
                    <Text as="span" color={theme.white} fontSize={14} fontWeight="bold">
                      {property && property.showHomeOpeningTime && property.showHomeOpeningTime.defaultText}
                    </Text>
                  </CustomText>
                </InfoBarContent>
              )}
            </CustomLink>
          </InfoBar>
        )}

        {props.takeover.link && props.takeover.text && (
          <InfoBar active>
            <Text color={theme.white} align="center" mt={10}>
              <a href={props.takeover.link} target="_blank" rel="nofollow noreferrer">
                {props.takeover.text} <Chevron size={12} color={theme.primary} />
              </a>
            </Text>
          </InfoBar>
        )}

        {props.children}

        {props.takeover.name.includes('propertypal-awards') && <TakeoverLogo src={awardsLogo.current} />}
      </Content>
    </Container>
  );
};

export default Takeover;
